import { render, staticRenderFns } from "./PokemonCard.vue?vue&type=template&id=c9a3b2ba&scoped=true&"
import script from "./PokemonCard.vue?vue&type=script&lang=js&"
export * from "./PokemonCard.vue?vue&type=script&lang=js&"
import style0 from "./PokemonCard.vue?vue&type=style&index=0&id=c9a3b2ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9a3b2ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VCardTitle,VChip,VExpandTransition,VHover,VImg,VProgressLinear,VSpacer})
